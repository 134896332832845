
.form-check {
	padding-left: 0;

	label {
		display: block !important;
		width: 100%;
		// cursor: pointer;
		position: relative;
		margin-top: 16px;
	}

	input {
		position: absolute;
		left: 16px;
		top: 16px;

		&.position-inherit {
			position: inherit;
			top: 0;
			left: 0;
		}
	}

	.form-check-input {
		width: 24px;
		height: 24px;
		margin-top: 0;
		margin-left: 0;
		border: 1px solid rgba(60, 60, 67, 0.1);
		box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
		margin-right: 8px;

		// &:hover {
		// 	cursor: pointer;
		// }

		&[type="image"] {
			border: none;
		}

		&:disabled {
			background: rgba(60, 60, 67, 0.24);
			opacity: 1;
			box-shadow: none;
			border: none;
		}

		&:checked {
			background-color: #fff;
			border: 1px solid rgba(60, 60, 67, 0.1);

			&[type="radio"] {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23007aff'/%3e%3c/svg%3e");
			}

			& ~ .form-check-label {
				color: #3c3c43;
			}

			&:disabled[type="radio"] {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%233C3C438F'/%3e%3c/svg%3e");
			}
		}

		&:focus {
			box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
		}
	}

	span {
		display: block;
		padding: 16px 16px 16px 48px;
		border: 1px solid rgba(60, 60, 67, 0.16);
		border-radius: 8px;
		transition: all 0.25s linear;
		font-size: 16px;
		line-height: 1.5;
	}

	input:checked + span {
		border: 1px solid #007aff;
	}

	input:disabled + span {
		background: #efefef;
		color: rgba(60, 60, 67, 0.56);
		border: 0;
	}
}

#teamAccordion {
	.accordion-item {
		border: none;
	}

	.form-check {
		margin-bottom: 0;
	}

	input:checked + span {
		border: 1px solid #007aff;
		// border-bottom: 0;
		// border-bottom-left-radius: 0;
		// border-bottom-right-radius: 0;
		background: #dbeafe;
	}

	.accordion-collapse {
		position: relative;
		top: -10px;
		background: rgb(255, 255, 255);
		transition: all 0.25s linear;
	}

	.accordion-body {
		border: 1px solid #007aff;
		border-top: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		& > div {
			margin-top: 14px;

			&:first-child {
				margin-top: 0;
			}
		}
	}
	.accordion-header :not(.collapsed) {
		span {
			&:after {
				font-family: "Material Icons";
				content: "\e5cf";
				float: right;
				font-size: 18px;
			}
		}
	}
	.accordion-header.collapsed {
		span {
			&:after {
				font-family: "Material Icons";
				content: "\e5ce";
				float: right;
				font-size: 18px;
			}
		}
	}
}

.image {
	width: 24px;
	height: 24px;
	overflow: hidden;
	border-radius: 50%;
	background: #efefef;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		vertical-align: top;
	}

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		font-weight: bold;
		height: 100%;
	}
}
