
// .course-info {
// 	h2,
// 	h3 {
// 		font-weight: bold;
// 	}

// 	h2 {
// 		// font-size: 20px;
// 		// line-height: 28px;
// 		font-size: 24px;
// 		line-height: 1.2;
// 		margin-bottom: 8px;
// 	}

// 	h3 {
// 		font-size: 12px;
// 	}

// 	p {
// 		font-size: 14px;
// 		margin-bottom: 8px;
// 	}

// 	& ~ a {
// 		font-weight: normal;
// 		color: #007aff;
// 		font-size: 12px;
// 	}

// 	.course-info-collapse {
// 		label {
// 			font-size: 12px;
// 		}
// 	}
// }
.contentBody {
	height: calc(100% - 134px);
}

.contentBody-danger {
	height: calc(100% - 134px - 44px);
}
